<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form
        class="OChannelChatForm"
        novalidate
        @submit.prevent="handleSubmit(onSubmit)"
    >
      <m-input-with-validation
          v-model="form.msg"
          class="font-body-s"
          :at-mention-fetch="fetchUsersToMention"
          :at-mention-nothing-found="$t('dict.notFoundMessageMentionedChannelMembers')"
          :disabled="sendButtonDisabled"
          :placeholder="$t('dict.atMentionPlaceholder')"
          name="message"
          type="textarea"
          :is-resizable="true"
          :isResizableMaxHeight="150"
          :isResizableMinHeight="75"
      />
      <b-button class="is-primary btn-send" native-type="submit" size="is-small" :disabled="sendButtonDisabled">
        {{ $t('dict.sendMsg') }}
      </b-button>
      <p v-if="!chatEnabled" class="has-text-centered pt-2 font-body-s">{{ $t('page.channel.chat.disabledMsg') }}</p>
    </form>
  </ValidationObserver>
</template>

<style lang="scss">
.OChannelChatForm {
  margin-top: auto;
  margin-right: 1.5rem;
  margin-left: 1.5rem;

  ::v-deep .textarea {
    max-height: 275px;
    font-size: 14px;

    // TODO: extend class
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: var(--default-border-radius);
      background-color: #eee;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: var(--default-border-radius);
      background-color: #bfbfbf;

      &:hover {
        background-color: darken(#bfbfbf, 10);
      }
    }
  }

  ::v-deep .emoji-picker {
    margin-top: -40px;
    margin-left: 156px;
  }

  ::v-deep .emoji-button {
    position: absolute;
    bottom: -31px;
    left: 5px;
  }
}

.btn-send {
  display: flex;
  margin-top: .5rem;
  margin-left: auto;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuthenticationStore, LifferyTourStore } from '@/store';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';
import { ValidationObserver } from 'vee-validate';
import { formatMentionUserComponentData } from '@/utils/formatMentionUsersData';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import isTouchDevice from '@/utils/isTouchScreen';

@Component({
  components: {
    MInputWithValidation,
    ValidationObserver,
  }
})
export default class OChannelChatForm extends Vue {
  @Prop({ required: true })
  channelSlug!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: true })
  chatEnabled!: boolean;

  form: { msg: string } = { msg: '' };

  get sendButtonDisabled (): boolean {
    if (!this.chatEnabled) {
      return true;
    }
    return this.loading;
  }

  get isTouchScreen (): boolean {
    return isTouchDevice();
  }

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  async fetchUsersToMention (searchText: string, cb: (a: any) => any): Promise<void> {
    const { data } = await ChannelMemberService.channelMemberSlugGet(
      { slug: this.channelSlug },
      { text: searchText }
    );

    const userObj = formatMentionUserComponentData(data, this.currentUser.username);
    cb(userObj);
  }

  onSubmit (): void {
    if (this.form.msg.trim() === '') {
      return;
    }
    this.$emit('send-message', this.form.msg);
    this.form.msg = '';

    // if the user has sent a message, we won't open the chat again
    LifferyTourStore.CHANNEL_CHAT_OPENED(3);
  }
}
</script>
